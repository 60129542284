import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import { getUrlImagesFB } from "../../../services/firebase";
import DefaultSucessPage from "./DefaultSucessPage/index.jsx";
import GeneralSucessPage from "./GeneralSucessPage/index.jsx";
import SuccessSkeleton from "../components/Skeletons/SuccessSkeleton";
import { getOffersByBusinessId } from "../../../graphql/Query/offers";
import { getBusinessPage } from "../../../services/apis/biud/fetchs.js";

export default function Success() {
  const params = useParams();
  const location = useLocation();

  const [logo, setLogo] = useState("");
  const [buttons, setButtons] = useState([]);
  const [cupomImg, setCupomImg] = useState();
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState();
  const [groupBusinessId, setGroupBusinessId] = useState(0);
  const [hasValidOffer, setHasValidOffer] = useState(false);

  const data = useCallback(() => {
    setCustomerData(location.state?.cpf);
  }, [location.state?.cpf]);

  const getInfos = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getBusinessPage({
        businessId: parseInt(params.businessId),
      });
      const businessInfo = res?.data;
      const offers = await getOffersByBusinessId(parseInt(params.businessId));
      const hasImgCupom = businessInfo.page.imgCupom;
      const fetchLogo = businessInfo.page.logo;
      const filteredOffers = offers.filter(
        (offer) =>
          moment(`${offer.fromDate} 00:00:00`, "DD/MM/YYYY hh:mm:ss") <=
            moment() &&
          moment(`${offer.toDate} 23:59:59`, "DD/MM/YYYY hh:mm:ss") >= moment()
      );

      if (hasImgCupom) {
        const imageCupom = await getUrlImagesFB(businessInfo.page.imgCupom);
        setCupomImg(imageCupom);
      }

      if (fetchLogo) {
        const image = await getUrlImagesFB(businessInfo.page.logo);
        setLogo(image);
      }

      setGroupBusinessId(businessInfo?.group.id);
      setButtons(businessInfo?.page?.actions);
      setHasValidOffer(Boolean(filteredOffers.length));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [location.state?.cpf, params.businessId]);

  useEffect(() => {
    if (!location.state?.isGeneral) {
      getInfos();
      data();
    } else {
      setLoading(false);
    }
  }, [data, getInfos]);

  return loading ? (
    <SuccessSkeleton />
  ) : location.pathname.includes("hotlead") ? (
    <GeneralSucessPage
      buttons={buttons}
      customerName={location.state?.name || ""}
      cupomImg={cupomImg}
      logo={logo}
      loading={loading}
      location={location}
      params={params}
      customerData={customerData}
      groupBusinessId={groupBusinessId}
      hasValidOffer={hasValidOffer}
    />
  ) : (
    <DefaultSucessPage
      buttons={buttons}
      cupomImg={cupomImg}
      logo={logo}
      loading={loading}
      location={location}
      params={params}
      customerData={customerData}
      groupBusinessId={groupBusinessId}
      hasValidOffer={hasValidOffer}
    />
  );
}
