import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import {
  GeneralBrands,
  GeneralWelcome,
  GoodBye,
  NotFound,
  OffersToCustomer,
  Welcome,
  WelcomeSuccess,
} from "./pages";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<NotFound />}>
      <Route path="/welcome/:id" element={<Welcome />} />
      <Route path="/hotlead/welcome/:id" element={<GeneralWelcome />} />
      <Route path="/goodbye/:id/:cpf" element={<GoodBye />} />
      <Route path="/goodbye/group/:id/:cpf" element={<GoodBye />} />
      <Route
        path="/hotlead/gsdepartments/:businessId"
        element={<GeneralBrands />}
      />
      <Route path="/success/:businessId" element={<WelcomeSuccess />} />
      <Route path="/hotlead/success/:businessId" element={<WelcomeSuccess />} />
      <Route
        path="/offersCustomer/:businessId"
        element={<OffersToCustomer />}
      />
    </Route>
  )
);

export default router;
